import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "../../../../environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";

@Injectable({ providedIn: "root" })
export class SystemConfigsService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = `${this.env.getValueTransferState(API_URL)}/app/systemProperty`;
  }

  getSystemConfigs() {
    return this.http.get<any>(this.apiUrl);
  }

  updateSystemConfig(id: string, propertyValue: string) {
    return this.http.put<any>(`${this.apiUrl}/${id}`, { propertyValue });
  }
}
