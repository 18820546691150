import { State, Action, StateContext, Selector } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { LoadSystemConfigs, UpdateSystemConfig } from "./system-configs.actions";
import { AdminPagesStateDefaults, SetError } from "../../admin.state";
import { SystemConfigsStateModel } from "./system-configs.model";
import { SystemConfigsService } from "../system-configs.service";

@Injectable()
@State<SystemConfigsStateModel>({
  name: "systemConfigs",
  defaults: AdminPagesStateDefaults
})
export class SystemConfigsState {
  constructor(
    private systemConfigsService: SystemConfigsService
  ) {}

  @Selector() static configs(state: SystemConfigsStateModel) { return state.items; }
  @Selector() static loading(state: SystemConfigsStateModel) { return state.loading; }
  @Selector() static saving(state: SystemConfigsStateModel) { return state.saving; }

  @Action(LoadSystemConfigs)
  loadSystemConfigs(ctx: StateContext<SystemConfigsStateModel>) {
    ctx.patchState({
      items: [],
      loading: true
    });

    return this.systemConfigsService.getSystemConfigs().pipe(
      tap(
        (response) => {
          ctx.patchState({
            items: response.items,
            loading: false
          });
        },
        (error) => {
          ctx.patchState({ items: [], loading: false });
          ctx.dispatch(new SetError({ loading: error }));
        }
      )
    );
  }

  @Action(UpdateSystemConfig)
  updateSystemConfig(ctx: StateContext<SystemConfigsStateModel>, action: UpdateSystemConfig) {
    ctx.patchState({ saving: true });
    
    return this.systemConfigsService.updateSystemConfig(action.id, action.value).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
          ctx.dispatch(new LoadSystemConfigs());
          ctx.patchState({
            saving: false
          });
        },
        (error) => {
          action.callbackError();
          ctx.patchState({ saving: false });
        }
      )
    );
  }
}
