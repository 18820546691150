export class LoadSystemConfigs {
  public static readonly type = "[System Configs] load system sonfigs";
}

export class UpdateSystemConfig {
  public static readonly type = "[System Configs] update system config";
  constructor(
    public readonly id: string,
    public readonly value: string,
    public readonly callbackSuccess: () => void,
    public readonly callbackError: () => void
    ) {}
}
